
import getClassName from "@/utils/getClassName";
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";

import { overallData } from "@/utils/simulation";

import Dialog from "@/components/Dialog/index.vue";
import * as echarts from "echarts";
import { Line, Yield, Motor } from "@/assets/echarts";

import CommonModule from "./components/CommonModule.vue";

import px2rem from "@/utils/px2rem";

import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  nextTick,
} from "vue";
export default defineComponent({
  name: "",
  components: { Dialog, CommonModule },
  setup() {
    const store = useStore();
    const data = reactive({
      Automation: {
        pageId: "100001",
        projectId: store.state.projectId,
      },
      pageTabs: <any>[],
      ShotBlasting: <any>[{}],
      StateFlow: [],
      Time: <any>null, // 强制更新子组件
      tableObj: <any>{},
      alertInfoList: {},
    });
    onBeforeMount(() => {});
    onMounted(() => {
      // 获取自动化监控数据
      getbyPage();
      LnfoAlert();
    });

    const getbyPage = () => {
      Api.commonPost(
        "dap",
        "byPage",
        data.Automation,
        (res: any) => {
          let {
            data: { pageTabs },
          } = res;
          data.pageTabs = pageTabs;
          data.pageTabs.forEach((element: any) => {
            if (element.id == 1000011) {
              element.wd = "470px";
              element.img = require("@/assets/img/monitoring/system.png");
              element.boxwd = "472px";
              element.boxhd = "311px";
              element.bgimg = require("@/assets/img/monitoring/publicbgImg.png");
              element.flexwd = "50%";
            }
            if (element.id == 1000012) {
              element.wd = "850px";
              element.img = require("@/assets/img/monitoring/warm.png");
              element.boxwd = "857px";
              element.boxhd = "311px";
              element.bgimg = require("@/assets/img/monitoring/centrebgImg.png");
              element.echratsShow = true;
              element.flexwd = "50%";
            }
            if (element.id == 1000013) {
              element.wd = "470px";
              element.img = require("@/assets/img/monitoring/quenching.png");
              element.boxwd = "472px";
              element.boxhd = "311px";
              element.bgimg = require("@/assets/img/monitoring/publicbgImg.png");
              element.flexwd = "33%";
            }
            if (element.id == 1000016) {
              element.wd = "470px";
              element.img = require("@/assets/img/monitoring/abnormal.png");
              element.boxwd = "472px";
              element.boxhd = "311px";
              element.bgimg = require("@/assets/img/monitoring/publicbgImg.png");
            }
            if (element.id == 1000014) {
              element.wd = "850px";
              element.img = require("@/assets/img/monitoring/shot.png");
              element.boxwd = "857px";
              element.boxhd = "311px";
              element.bgimg = require("@/assets/img/monitoring/centrebgImg.png");
              element.echratsShow = true;
              element.flexwd = "50%";
            }
            if (element.id == 1000015) {
              element.wd = "470px";
              element.img = require("@/assets/img/monitoring/painting1.png");
              element.boxwd = "472px";
              element.boxhd = "311px";
              element.bgimg = require("@/assets/img/monitoring/publicbgImg.png");
              element.flexwd = "33%";
            }
            if (element.id == 1000017) {
              element.wd = "1873px";
              element.boxwd = "1873px";
              element.boxhd = "149px";
              element.bgimg = require("@/assets/img/monitoring/btmbgImg.png");
            }
          });
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    const LnfoAlert = () => {
      Api.commonPost(
        "dap",
        "LnfoAlert",
        {
          companyId: store.state.companyId,
          projectId: store.state.projectId,
        },
        (res: any) => {
          data.alertInfoList = res.data.alertInfoList;
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    const publicEcharts = (Dom: any, Data: any) => {
      let myChart = echarts.init(Dom);
      myChart.setOption(Data);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };

    // 触发弹框
    const MyMyDialog = <any>ref();
    const getDialog = () => {
      let item = {
        name: "异常报警",
        widths: "45%",
      };
      MyMyDialog.value.getShow(item);
    };

    return {
      ...toRefs(data),

      MyMyDialog,
      getDialog,
      getClassName,
      getbyPage,
      LnfoAlert,
      px2rem,
    };
  },
});
