import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70c04da6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main_box" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_publicFlex = _resolveComponent("publicFlex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      style: _normalizeStyle({ width: _ctx.px2rem(_ctx.pageTabs.wd) }),
      class: "img_s",
      src: _ctx.pageTabs?.img,
      alt: ""
    }, null, 12, _hoisted_2),
    _createElementVNode("div", {
      style: _normalizeStyle({
        width: _ctx.px2rem(_ctx.pageTabs.boxwd),
        height: _ctx.px2rem(_ctx.pageTabs.boxhd),
        backgroundImage: `url(${_ctx.pageTabs.bgimg})`,
      }),
      class: "module_box"
    }, [
      _createVNode(_component_publicFlex, {
        AllData: _ctx.pageTabs,
        listData: _ctx.listData
      }, null, 8, ["AllData", "listData"])
    ], 4)
  ]))
}