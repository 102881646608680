
import Api from "@/assets/api/api";
import { ElMessage } from "element-plus";
import MySelect from "@/components/MySelect/index.vue";
import newline from "@/components/newline/index.vue";
import Dialog from "@/components/Dialog/index.vue";
import getClassColor from "@/utils/getClassName";
import SvgIcon from "@/components/SvgIcon/index.vue";
import * as echarts from "echarts";
import { orSo, Motor, ball } from "@/assets/echarts";
import px2rem from "@/utils/px2rem";

import rollList from "./rollList.vue";
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
  ref,
} from "vue";
export default defineComponent({
  name: "",
  components: { SvgIcon, rollList, Dialog, newline, MySelect },
  props: {
    AllData: {
      type: Object,
    },
    listData: {
      type: Array,
    },
  },
  setup(props) {
    const store = useStore();
    const warm = ref();
    const motor = ref();
    const balls = ref();
    const Dialog = ref();
    const data = reactive({
      cutShow: true,
      WorkpieceList2: [],
      list: [],
      stateList: <any>[],
      orSoData: <any>{},
      MotorData: <any>{},
      ballData: <any>{},
      systemList: [],
      echartslist: [],
      colors: {
        background: `linear-gradient(180deg, #ffffff 0%, #ff0000 100%)`,
      },
      echo: "",
      echo2: "",
      WorkpieceList: [],
      CraftList: [],
      craftData: {
        findOnlyUsable: 0,
        produceItemCode: 100,
        projectId: store.state.projectId,
      },
      setModel: <any>{
        modelId: 0,
        produceItemCode: 0,
        projectId: store.state.projectId,
      },
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      data.orSoData = orSo();
      data.MotorData = Motor();
      data.ballData = ball();

      getData(props.AllData);
    });

    watch(
      props,
      (newValue) => {
        console.log("触发了");
        getData(newValue.AllData);
      },
      { deep: true }
    );

    const getData = (Data: any) => {
      let AllData = JSON.parse(JSON.stringify(Data));
      if (AllData.id == "1000017") {
        data.stateList = [];
        let conveyorList = <any>[];
        let chunk = <any>{};
        AllData.dataValues.forEach((item: any) => {
          item.dataItemValues.forEach((it: any, i: any) => {
            it.wd = "68px";
            it.hd = "77px";
            if (i == 0) {
              chunk = it;
            }
            if (i == 1) {
              chunk.value = it.value; //状态
              if (
                it.metaDataItemId == "1000017101" ||
                it.metaDataItemId == "1000017301" ||
                it.metaDataItemId == "1000017501" ||
                it.metaDataItemId == "1000017701" ||
                it.metaDataItemId == "1000017901"
              ) {
                chunk.svg = `jiqiren-${getClassColor(it.value)}`;
              }

              // 淬火
              if (it.metaDataItemId == "1000017401") {
                chunk.svg = `cuihuo-${getClassColor(it.value)}`;
              }
              // 喷油
              if (it.metaDataItemId == "1000017601") {
                chunk.svg = `penyou-${getClassColor(it.value)}`;
              }
              // 定位
              if (it.metaDataItemId == "1000017801") {
                chunk.svg = `dingwei-${getClassColor(it.value)}`;
              }
              // 冲压
              if (it.metaDataItemId == "1000017001") {
                chunk.svg = `chongya-${getClassColor(it.value)}`;
              }
            }
            if (i == 2) {
              chunk.num = +it.value;
            }
            if (item.groupInfo.id == "10000172" && i > 1) {
              //加热炉
              it.value = +it.value;
              conveyorList.push(it);
            }

            if (item.dataItemValues.length - 1 == i) {
              if (item.groupInfo.id == "10000172") {
                chunk.conveyorList = conveyorList;
                console.log(chunk);
              }
              data.stateList.push(chunk);
            }
          });
        });

        console.log(data.stateList);
      }

      AllData?.dataValues?.forEach((item: any) => {
        // 系统信息
        if (item.groupInfo.id == "10000111") {
          getList();
          data.systemList = item.dataItemValues;

          data.systemList.forEach((item: any) => {
            if (
              item.metaDataItemId == "1000011107" ||
              item.metaDataItemId == "1000011106"
            ) {
              item.parallel = true;
              item.color = "#38FFF2";
            }
            item.wd = "50px";
            item.hd = "50px";
            // 系统状态
            if (item.metaDataItemId == "1000011100") {
              item.svg = "operation";
            }
            // 工件类型
            if (item.metaDataItemId == "1000011101") {
              item.svg = "workpiece";
            }
            // 产线模式
            if (item.metaDataItemId == "1000011102") {
              item.svg = "craft";
            }
            // 当前操作员
            if (item.metaDataItemId == "1000011104") {
              item.svg = "personnel";
            }
          });

          // data.list = item.dataItemValues;
        }

        // 加热炉
        if (item.groupInfo.id == "10000121") {
          data.list = item.dataItemValues;
          // setTimeout(() => {
          //   publicEcharts(warm?.value, data.orSoData);
          // }, 100);
        }

        // 加热炉折线图
        if (item.groupInfo.id == "10000122") {
          let list = item.dataItemValues;
          data.orSoData.xAxis[0].data = list.map((it: any) => it.title);
          // data.orSoData.series[1].data = list.map((it: any) => it.value);
          data.orSoData.series[1].data = list.map((it: any) => 1200);
        }
        if (item.groupInfo.id == "10000123") {
          let list = item.dataItemValues;
          data.orSoData.series[0].data = list.map((it: any) => it.value);
        }

        if (item.groupInfo.id == "10000124") {
          let list = item.dataItemValues;
          data.orSoData.series[3].data = list.map((it: any) => it.value); //设定温度
          data.orSoData.series[3].data = list.map((it: any) => 1200); //设定温度
        }

        if (item.groupInfo.id == "10000125") {
          //运行温度
          let list = item.dataItemValues;
          data.orSoData.series[2].data = list.map((it: any) => it.value);

          setTimeout(() => {
            publicEcharts(warm?.value, data.orSoData);
          }, 100);
        }

        // 淬火
        if (item.groupInfo.id == "10000131") {
          data.list = item.dataItemValues;
        }
        // 抛丸
        if (item.groupInfo.id == "10000141") {
          data.list = item.dataItemValues;
        }

        // 抛丸echarts
        if (item.groupInfo.id == "10000142") {
          let list = item.dataItemValues;
          data.MotorData.title.text = item.groupInfo.groupTitle;
          data.MotorData.series[0].data = list.map((it: any) => it.value);
          data.MotorData.xAxis[0].data = list.map((it: any) => it.title);

          setTimeout(() => {
            publicEcharts(motor?.value, data.MotorData);
          }, 300);
        }

        // 喷油
        if (item.groupInfo.id == "10000151") {
          data.list = item.dataItemValues;
        }

        // 喷油底部水池
        if (item.groupInfo.id == "10000152") {
          data.echartslist = item.dataItemValues;

          setTimeout(() => {
            data.echartslist.forEach((it: any, i) => {
              data.ballData.title[0].text = it.title;
              data.ballData.series[0].data = [it.value, it.value];
              publicEcharts(balls?.value[i], data.ballData);
            });
          }, 300);
        }
        // 底部状态流程
        // if (item.groupInfo.id == "10000171") {
        //   data.stateList = item.dataItemValues;
        //   data.stateList.forEach((item: any) => {
        //     item.wd = "68px";
        //     item.hd = "77px";
        //     if (
        //       item.metaDataItemId == "1000017101" ||
        //       item.metaDataItemId == "1000017123" ||
        //       item.metaDataItemId == "1000017127" ||
        //       item.metaDataItemId == "1000017133" ||
        //       item.metaDataItemId == "1000017135"
        //     ) {
        //       item.svg = `jiqiren-${getClassColor(item.value)}`;
        //     }
        //     // 加热炉
        //     if (item.metaDataItemId == "1000017103") {
        //       item.conveyorList = [
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //         { value: 4 },
        //       ];

        //       item.svg = `jiarelu-${getClassColor(item.value)}`;
        //       item.wd = "341px";
        //       item.hd = "70px";
        //     }
        //     // 淬火
        //     if (item.metaDataItemId == "1000017125") {
        //       item.svg = `cuihuo-${getClassColor(item.value)}`;
        //     }
        //     // 喷油
        //     if (item.metaDataItemId == "1000017129") {
        //       item.svg = `penyou-${getClassColor(item.value)}`;
        //     }
        //     // 定位
        //     if (item.metaDataItemId == "1000017131") {
        //       item.svg = `dingwei-${getClassColor(item.value)}`;
        //     }
        //     // 冲压
        //     if (item.metaDataItemId == "1000017137") {
        //       item.svg = `chongya-${getClassColor(item.value)}`;
        //     }
        //   });
        // }
      });
    };

    // 公共echarts
    const publicEcharts = (Dom: any, Data: any) => {
      let myChart = echarts.init(Dom);
      myChart.setOption(Data);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };

    // 点击触发弹框
    const getPopup = (Data: any) => {
      if (Data.metaDataItemId == "1000011102") {
        data.cutShow = true;
        Dialog.value.getShow({ name: "切换模式" });
      }
      if (Data.metaDataItemId == "1000011104") {
        data.cutShow = false;
        Dialog.value.getShow({ name: "交班信息", widths: "30%" });
      }
    };

    const getList = () => {
      Api.commonPost(
        "production",
        "workpieceList",
        { projectId: store.state.projectId },
        (res: any) => {
          console.log(res);
          data.WorkpieceList = res.data.map((item: any, i: any) => {
            return { id: item.id, value: item.itemCode, label: item.itemName };
          });
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    // 获取生产模式
    const getModelUsable = (id: any) => {
      Api.commonPost(
        "production",
        "ModelUsable",
        data.craftData,
        (res: any) => {
          data.CraftList = res.data.map((item: any, i: any) => {
            return {
              id: item.id,
              label: item.modelName,
              value: item.id,
              usableStatus: item.usableStatus,
              typeName: item.usableStatus == 0 ? "禁用" : "启用",
              color: item.usableStatus == 0 ? "red" : "green",
              ...item,
            };
          });
          if (data.CraftList.length == 0) data.echo2 = "";
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    const getChange = (e: any) => {
      data.craftData.produceItemCode = e;
      data.setModel.produceItemCode = e;
      getModelUsable("");
    };

    const getChange2 = (e: any) => {
      data.setModel.modelId = e;
    };

    const getConfirm = () => {
      if (data.cutShow) {
        if (!data.setModel.produceItemCode) {
          ElMessage({
            message: "请选择工件类型",
            type: "warning",
          });
          return;
        }
        if (!data.setModel.modelId) {
          ElMessage({
            message: "请选择工艺模式",
            type: "warning",
          });
          return;
        }
        Api.commonPost(
          "production",
          "CutPattern",
          data.setModel,
          (res: any) => {
            ElMessage({
              message: "切换成功",
              type: "success",
            });
            data.setModel.modelId = "";
            data.setModel.produceItemCode = "";
          },
          (err: any) => {
            ElMessage.error(err.msg);
          }
        );
        Dialog.value.getClose("close");
      }
    };

    return {
      ...toRefs(data),
      warm,
      motor,
      balls,
      Dialog,
      px2rem,
      getClassColor,
      getPopup,
      getChange,
      getChange2,
      getConfirm,
    };
  },
});
