
import px2rem from "@/utils/px2rem";
import publicFlex from "./publicFlex.vue";
import Api from "@/assets/api/api";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  components: { publicFlex },
  props: {
    AllData: {
      type: Object,
    },
    listData: {
      type: Array,
    },
  },
  setup(props) {
    const store = useStore();
    const data = reactive({
      pageTabs: <any>[],
      WorkpieceList: [],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getData(props.AllData);
    });
    watch(
      props,
      (newValue) => {
        getData(JSON.parse(JSON.stringify(newValue.AllData)));
      },
      { deep: true }
    );

    const getData = (Data: any) => {
      data.pageTabs = Data;
    };

    return {
      ...toRefs(data),
      px2rem,
    };
  },
});
