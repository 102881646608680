<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
    v-on="$listeners"
    height="15"
    width="15"
    :style="style"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    // 颜色值
    color: {
      type: String,
      // default: "#999999",
      default: "blue",
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        "-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`,
      };
    },
    style() {
      return { "--color": this.color, "--stop-color": "ff0000" };
    },
  },
};
</script>

<style scoped>
/* .svg-icon {
  width: 1em;
    height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
} */

.svg-icon {
  /* width: 1em;
    height: 1em; */
  fill: currentColor;
  overflow: hidden;
  color: var(--color);
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
