import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-690fba6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row al-c ju-c list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonModule = _resolveComponent("CommonModule")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageTabs, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          style: _normalizeStyle({ width: _ctx.px2rem(item.wd) }),
          class: "content_box",
          key: item.id
        }, [
          _createVNode(_component_CommonModule, {
            AllData: item,
            listData: _ctx.alertInfoList
          }, null, 8, ["AllData", "listData"])
        ], 4))
      }), 128))
    ])
  ]))
}