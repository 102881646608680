<template>
  <div class="InfiniteScroll">
    <div class="scroll_parent_box" @mouseenter="mEnter" @mouseleave="mLeave">
      <div
        class="scroll_list"
        :style="{ transform: `translate(0px,-${scrollTop}px)` }"
      >
        <div ref="scrollItemBox">
          <div
            class="scroll_item"
            v-for="(item, index) in listData"
            :key="index"
          >
            <div class="List_box">
              <div class="row al-c ju-s">
                <div class="row al-c">
                  <p :style="{ color: getStyle(item.alertLevel) }">
                    [ {{ item.alertLevel == "LEVEL_30" ? "告警" : "预警" }} ]
                  </p>
                  <p>{{ item.alertTitle }}</p>
                </div>
                <p>{{ item.alertTime }}</p>
              </div>
              <!-- 内容区域 -->
              <el-tooltip :content="item.alertInfo" placement="top">
                <p class="text_hidden">{{ item.alertInfo }}</p>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div v-html="copyHtml"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { el } from "element-plus/es/locale";
let timer = null;
export default {
  name: "InfiniteScroll",
  props: {
    listData: {
      type: Array,
    },
  },
  data() {
    return {
      scrollTop: 0, //列表滚动高度
      speed: 30, //滚动的速度
      // listData: [
      //   {
      //     title: "无缝滚动第一行",
      //   },
      //   {
      //     title: "无缝滚动第二行",
      //   },
      //   {
      //     title: "无缝滚动第二行",
      //   },
      //   {
      //     title: "无缝滚动第二行",
      //   },
      // ], //表示有多少个列表项
      copyHtml: "", //复制多一份防止滚动到后面出现空白
    };
  },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
  },
  mounted() {
    // 如果列表数据是异步获取的，记得初始化在获取数据后再调用

    if (this.listData.length > 3) {
      this.initScroll();
    } else {
      clearInterval(timer);
    }
  },
  methods: {
    initScroll() {
      this.$nextTick(() => {
        this.copyHtml = this.$refs.scrollItemBox.innerHTML;
        this.startScroll();
      });
    },
    // 鼠标移入停止滚动
    mEnter() {
      clearInterval(timer);
    },
    // 鼠标移出继续滚动
    mLeave() {
      if (this.listData.length > 3) {
        this.startScroll();
      }
    },
    // 开始滚动
    startScroll() {
      timer = setInterval(this.scroll, this.speed);
    },
    // 滚动处理方法
    scroll() {
      this.scrollTop++;
      // 获取需要滚动的盒子的高度
      let scrollItemBox = this.$refs.scrollItemBox?.offsetHeight;
      // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
      if (this.scrollTop >= scrollItemBox) {
        this.scrollTop = 0;
      }
    },
    getStyle(alertLevel) {
      let color = alertLevel == "LEVEL_30" ? "red" : "orange";
      return color;
    },
  },
};
</script>
<style scoped lang="less">
.InfiniteScroll {
  box-sizing: border-box;
}

.scroll_parent_box {
  width: 100%;
  height: 300px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 10px;
}

.scroll_list {
  transition: all 0ms ease-in 0s;
}

.scroll_item {
}

.List_box {
  margin: 15px 5px;
  width: 433px;
  height: 81px;
  background: linear-gradient(
    90deg,
    rgba(105, 255, 250, 0.26) 0%,
    rgba(56, 255, 242, 0) 100%
  );
  border-radius: 5px;

  p {
    margin: 10px;
  }
}

.text_hidden {
  width: 426px;

  white-space: nowrap;

  text-overflow: ellipsis;

  overflow: hidden;
}
</style>
